<template>
  <header id="page-topbar">
    <AccountModal
      v-if="showAccountModal"
      @closeModal="showAccountModal = !showAccountModal"
    />
    <RegistroPontoModal
      v-if="showRegistroPontoModal"
      @closeModal="showRegistroPontoModal = !showRegistroPontoModal"
    />
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <router-link to="/home" class="logo logo-dark" title="Home">
            <span class="logo-sm">
              <img src="@/assets/images/logo/logo-sm-dark.png" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo/logo-dark.png" height="20" />
            </span>
          </router-link>
          <router-link to="/home" class="logo logo-light" title="Home">
            <span class="logo-sm">
              <img src="@/assets/images/logo/logo-sm-light.png" height="50" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo/logo-light.png" height="50" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          id="vertical-menu-btn"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          title="Expandir/Esconder o Menu"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block"
        >
          <template v-slot:button-content>
            <span class="d-xl-inline-block ml-1">{{ userName }}</span>
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </template>
          <span
            class="dropdown-item"
            title="Minha Conta"
            role="button"
            @click="showAccountModal = true"
          >
            <i class="ri-user-line align-middle mr-1"></i>
            Minha Conta
          </span>
          <span
            class="dropdown-item"
            title="Registrar Ponto"
            role="button"
            @click="showRegistroPontoModal = true"
          >
            <i class="ri-user-line align-middle mr-1"></i>
            Registrar Ponto
          </span>
          <div class="dropdown-divider"></div>
          <span
            class="dropdown-item text-danger"
            title="Sair"
            role="button"
            @click="logout"
          >
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Sair
          </span>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            title="Ativar/Desativar Tela Cheia"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right"
            title="Configurações de Layout"
            @click="toggleRightSidebar"
          >
            <i class="ri-settings-2-line toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>
  </header>
</template>

<script>
import PATHS from '@/router/routesMap'
import AccountModal from '@/components/account/AccountModal.vue'
import RegistroPontoModal from '@/components/account/RegistroPontoModal.vue'

export default {
  name: 'Topbar',

  components: {
    AccountModal,
    RegistroPontoModal,
  },

  data() {
    return {
      showAccountModal: false,
      showRegistroPontoModal: false,
    }
  },

  computed: {
    userName() {
      return this.$store.getters.user.name
    },
  },

  methods: {
    toggleMenu() {
      this.$parent.toggleMenu()
    },

    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT,
          )
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },

    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push(PATHS.BASE_URL)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>
