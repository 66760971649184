<template>
  <div class="vertical-menu">
    <simplebar class="h-100" ref="currentMenu" id="my-element">
      <div id="sidebar-menu">
        <ul class="metismenu list-unstyled" id="side-menu">
          <template>
            <li class="menu-title">Menu</li>
            <!-- Home -->
            <li>
              <router-link to="/home" class="side-nav-link-ref" title="Home">
                <i class="bx ri-home-line"></i>
                <span>Home</span>
              </router-link>
            </li>

            <!-- CLIENTES -->
            <li v-if="getModuleUserByCode('2')">
              <router-link
                to="/administration/dados-escola"
                class="side-nav-link-ref"
                title="Dados da Escola"
              >
                <i class="bx ri-building-4-line"></i>
                <span>Dados Escola</span>
              </router-link>
            </li>

            <!-- ESCOLAS -->
            <li v-if="getModuleUserByCode('0.01')">
              <router-link
                to="/administration/escolas"
                class="side-nav-link-ref"
                title="Escolas participantess"
              >
                <i class="bx ri-government-line"></i>
                <span>Escolas</span>
              </router-link>
            </li>

            <!-- CATEGORIAS -->
            <li v-if="getModuleUserByCode('0.04')">
              <router-link
                to="/administration/categorias"
                class="side-nav-link-ref"
                title="Categorias"
              >
                <i class="bx ri-list-check"></i>
                <span>Categorias</span>
              </router-link>
            </li>

            <!-- MODALIDADES -->
            <li v-if="getModuleUserByCode('0.03')">
              <router-link
                to="/administration/modalidades"
                class="side-nav-link-ref"
                title="Modalidades"
              >
                <i class="bx ri-body-scan-line"></i>
                <span>Modalidades</span>
              </router-link>
            </li>

            <!-- TÉCNICOS -->
            <li v-if="getModuleUserByCode('0.06')">
              <router-link
                to="/administration/tecnicos"
                class="side-nav-link-ref"
                title="Técnicos"
              >
                <i class="bx ri-user-voice-line"></i>
                <span>Técnicos</span>
              </router-link>
            </li>

            <!-- Atletas -->
            <li v-if="getModuleUserByCode('0.05')">
              <router-link
                to="/administration/atletas"
                class="side-nav-link-ref"
                title="Atletas"
              >
                <i class="bx ri-group-line"></i>
                <span>Atletas</span>
              </router-link>
            </li>

            <!-- TIMES -->
            <li v-if="getModuleUserByCode('0.07')">
              <router-link
                to="/administration/times"
                class="side-nav-link-ref"
                title="Times"
              >
                <i class="bx ri-team-line"></i>
                <span>Times</span>
              </router-link>
            </li>

            <!-- Equipe de Apoio -->
            <li v-if="getModuleUserByCode('0.09')">
              <router-link
                to="/administration/equipe-apoio"
                class="side-nav-link-ref"
                title="Equipe de apoio"
              >
                <i class="bx ri-team-line"></i>
                <span>Equipe de Apoio</span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import simplebar from 'simplebar-vue'
import MetisMenu from 'metismenujs/dist/metismenujs'

export default {
  name: 'SidebarLeft',

  components: {
    simplebar,
  },

  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  watch: {
    $route: {
      handler: 'onRoutechange',
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'dark':
              document.body.setAttribute('data-sidebar', 'dark')
              document.body.removeAttribute('data-topbar')
              document.body.removeAttribute('data-sidebar-size')
              break
            case 'light':
              document.body.setAttribute('data-topbar', 'dark')
              document.body.removeAttribute('data-sidebar')
              document.body.removeAttribute('data-sidebar-size')
              document.body.classList.remove('vertical-collpsed')
              break
            case 'compact':
              document.body.setAttribute('data-sidebar-size', 'small')
              document.body.setAttribute('data-sidebar', 'dark')
              document.body.classList.remove('vertical-collpsed')
              document.body.removeAttribute('data-topbar', 'dark')
              break
            case 'icon':
              document.body.setAttribute('data-keep-enlarged', 'true')
              document.body.classList.add('vertical-collpsed')
              document.body.setAttribute('data-sidebar', 'dark')
              document.body.removeAttribute('data-topbar', 'dark')
              break
            case 'colored':
              document.body.setAttribute('data-sidebar', 'colored')
              document.body.removeAttribute('data-keep-enlarged')
              document.body.classList.remove('vertical-collpsed')
              document.body.removeAttribute('data-sidebar-size')
              break
            default:
              document.body.setAttribute('data-sidebar', 'dark')
              break
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'boxed':
              document.body.setAttribute('data-layout-size', 'boxed')
              break
            case 'fluid':
              document.body.setAttribute('data-layout-mode', 'fluid')
              document.body.removeAttribute('data-layout-size')
              break
            default:
              document.body.setAttribute('data-layout-mode', 'fluid')
              break
          }
        }
      },
    },
  },

  methods: {
    onRoutechange() {
      setTimeout(() => {
        const currentPosition =
          document.getElementsByClassName('mm-active')[0].offsetTop
        if (currentPosition > 400)
          this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 200
      }, 300)
    },
  },

  mounted() {
    const menuRef = new MetisMenu('#side-menu')
    const links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null

    for (let i = 0; i < links.length; i += 1) {
      if (
        window.location.pathname === links[i].pathname ||
        window.location.pathname.includes(links[i].pathname)
      ) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      const parent = matchingMenuItem.parentElement

      if (parent) {
        parent.classList.add('mm-active')
        const parent2 = parent.parentElement.closest('ul')

        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show')

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('mm-active')
            const childAnchor = parent3.querySelector('.has-arrow')
            const childDropdown = parent3.querySelector('.has-dropdown')
            if (childAnchor) childAnchor.classList.add('mm-active')
            if (childDropdown) childDropdown.classList.add('mm-active')

            const parent4 = parent3.parentElement
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show')
              const parent5 = parent4.parentElement
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active')
                const childanchor = parent5.querySelector('.is-parent')
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active')
                }
              }
            }
          }
        }
      }
    }
  },
}
</script>
